// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-cac-7299-cb-1-d-48294-fdbde-66-d-9-a-13-fcf-7-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/.cache/gatsby-mdx/mdx-wrappers-dir/cac7299cb1d48294fdbde66d9a13fcf7--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-cac-7299-cb-1-d-48294-fdbde-66-d-9-a-13-fcf-7-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---src-pages-index-js": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-2018-11-26-writing-involves-choices-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-26-writing-involves-choices.mdx" /* webpackChunkName: "component---src-pages-2018-11-26-writing-involves-choices-mdx" */),
  "component---src-pages-2018-11-27-how-do-we-choose-our-words-phrases-sentences-and-why-does-it-matter-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-how-do-we-choose-our-words—phrases—sentences-and-why-does-it-matter.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-how-do-we-choose-our-words-phrases-sentences-and-why-does-it-matter-mdx" */),
  "component---src-pages-2018-11-27-how-do-phrases-and-sentences-create-a-reader-experience-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-how-do-phrases-and-sentences-create-a-reader-experience.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-how-do-phrases-and-sentences-create-a-reader-experience-mdx" */),
  "component---src-pages-2018-11-27-how-can-teachers-help-students-make-the-most-of-writing-instruction-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-how-can-teachers-help-students-make-the-most-of-writing-instruction.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-how-can-teachers-help-students-make-the-most-of-writing-instruction-mdx" */),
  "component---src-pages-2018-11-27-how-do-we-write-in-the-digital-age-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-how-do-we-write-in-the-digital-age.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-how-do-we-write-in-the-digital-age-mdx" */),
  "component---src-pages-2018-11-27-what-is-multimodal-composition-and-where-do-people-encounter-it-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-what-is-multimodal-composition-and-where-do-people-encounter-it.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-what-is-multimodal-composition-and-where-do-people-encounter-it-mdx" */),
  "component---src-pages-2018-11-27-what-is-word-choice-and-why-does-it-matter-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-what-is-word-choice-and-why-does-it-matter.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-what-is-word-choice-and-why-does-it-matter-mdx" */),
  "component---src-pages-2018-11-27-when-would-writers-use-multimodal-composition-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-when-would-writers-use-multimodal-composition.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-when-would-writers-use-multimodal-composition-mdx" */),
  "component---src-pages-2018-11-28-how-do-writers-respond-to-audience-expectations-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-28-how-do-writers-respond-to-audience-expectations.mdx" /* webpackChunkName: "component---src-pages-2018-11-28-how-do-writers-respond-to-audience-expectations-mdx" */),
  "component---src-pages-2018-11-27-why-do-choices-in-writing-matter-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-why-do-choices-in-writing-matter.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-why-do-choices-in-writing-matter-mdx" */),
  "component---src-pages-2018-11-28-what-does-it-mean-to-write-for-an-audience-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-28-what-does-it-mean-to-write-for-an-audience.mdx" /* webpackChunkName: "component---src-pages-2018-11-28-what-does-it-mean-to-write-for-an-audience-mdx" */),
  "component---src-pages-2018-12-28-about-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-12-28-about.mdx" /* webpackChunkName: "component---src-pages-2018-12-28-about-mdx" */),
  "component---src-pages-how-does-feedback-from-others-help-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/how-does-feedback-from-others-help.mdx" /* webpackChunkName: "component---src-pages-how-does-feedback-from-others-help-mdx" */),
  "component---src-pages-2019-04-11-about-the-data-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2019-04-11-about-the-data.mdx" /* webpackChunkName: "component---src-pages-2019-04-11-about-the-data-mdx" */),
  "component---src-pages-2018-12-20-about-the-study-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-12-20-about-the-study.mdx" /* webpackChunkName: "component---src-pages-2018-12-20-about-the-study-mdx" */),
  "component---src-pages-how-is-writing-a-social-activity-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/how-is-writing-a-social-activity.mdx" /* webpackChunkName: "component---src-pages-how-is-writing-a-social-activity-mdx" */),
  "component---src-pages-what-can-feedback-do-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/what-can-feedback-do.mdx" /* webpackChunkName: "component---src-pages-what-can-feedback-do-mdx" */),
  "component---src-pages-what-can-writers-do-to-make-feedback-productive-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/what-can-writers-do-to-make-feedback-productive.mdx" /* webpackChunkName: "component---src-pages-what-can-writers-do-to-make-feedback-productive-mdx" */),
  "component---src-pages-what-is-feedback-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/what-is-feedback.mdx" /* webpackChunkName: "component---src-pages-what-is-feedback-mdx" */),
  "component---src-pages-writing-is-social-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/writing-is-social.mdx" /* webpackChunkName: "component---src-pages-writing-is-social-mdx" */),
  "component---src-pages-2018-11-27-how-do-writers-compose-in-the-digital-age-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-27-how-do-writers-compose-in-the-digital-age.mdx" /* webpackChunkName: "component---src-pages-2018-11-27-how-do-writers-compose-in-the-digital-age-mdx" */),
  "component---src-pages-2018-11-28-how-does-making-choices-help-developing-writers-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-11-28-how-does-making-choices-help-developing-writers.mdx" /* webpackChunkName: "component---src-pages-2018-11-28-how-does-making-choices-help-developing-writers-mdx" */),
  "component---src-pages-how-does-feedback-work-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/how-does-feedback-work.mdx" /* webpackChunkName: "component---src-pages-how-does-feedback-work-mdx" */),
  "component---src-pages-2018-12-19-about-the-authors-mdx": () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/pages/2018-12-19-about-the-authors.mdx" /* webpackChunkName: "component---src-pages-2018-12-19-about-the-authors-mdx" */)
}

exports.data = () => import("/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/.cache/data.json")


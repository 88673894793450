module.exports = [{
      plugin: require('/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-136072240-1","head":true},
    },{
      plugin: require('/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/node_modules/gatsby-plugin-netlify-cms/gatsby-browser'),
      options: {"plugins":[],"modulePath":"/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/src/cms/cms.js"},
    },{
      plugin: require('/Users/scottsanzenbacher/Documents/Projects/Spaceship/developing-writers/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
